.blog-post {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: left; /* Ensure left alignment for readability */
  font-family: Arial, sans-serif;
}

/* Headings styling */
.blog-post h1 {
  margin-top: 40px;  /* Extra space above the title */
  margin-bottom: 10px;
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
}

.blog-post h2,
.blog-post h3 {
  margin-top: 40px;
  color: #1b1b1b;
  margin-bottom: 10px;
}

/* Date styling */
.blog-date {
  font-size: 0.9em;
  color: gray;
  margin-bottom: 20px;
  text-align: left;
}

/* Paragraph styling */
.blog-post p {
  font-size: 1.2em;
  line-height: 1.6;
  margin: 10px 0;
  font-weight: 400;
}

/* Image styling */
.blog-post img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
  display: block;
}

/* List styling */
.blog-post ul,
.blog-post ol {
  margin: 10px 0 10px 1.5rem; /* Indent list items for clarity */
  padding: 0;
  list-style-position: outside;
  text-align: left;
  font-size: 1.2em;
  line-height: 1.6;
}

.blog-post li {
  margin: 5px 0;
}