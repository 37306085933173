/* Container styling */
.blog-container {
  padding: 2rem;
  background-color: #ffffff;
}

/* Main title styling */
.blog-container h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #333;
}

/* Responsive grid layout for blog posts */
.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

/* Blog post summary card */
.blog-summary {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding-bottom: 1rem;
}

/* Hover effect on the card */
.blog-summary:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Title styling */
.blog-summary h2 {
  font-size: 1.5rem;
  margin: 1rem;
  text-align: left;
}

/* Link styling with hover effect */
.blog-summary h2 a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}
.blog-summary h2 a:hover {
  color: #1adce7;
}

/* Excerpt styling */
.blog-summary p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 500;
}

/* Date styling */
.blog-summary p.blog-date {
  font-size: 0.9rem;
  font-weight: 500;
  color: gray;
  text-align: left;
}

/* Cover image styling */
.blog-cover-image {
  width: 100%;
  height: auto;
  border-radius: 4px 4px 0px 0px;
}